.loader-overlay {
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: 100000;
}

.loader-content {
    height: auto;
    margin-left: auto;
    margin-top: auto;
    width: auto;
}

.loader-center {
    left: 50%;
    position: fixed;
    top: 50%;
z-index:1;
}

